import React from "react"
import SEO from "../components/seo"

import Layout2 from "../components/layout2";
import ImplantPage from "../components/ImplantPage/ImplantPage.js";
import Email from "../components/Email/EmailForm"

const ImplantsPage = () => (
  <Layout2>
    <SEO title="Implants" />
    <ImplantPage />
    <Email />
  </Layout2>
)

export default ImplantsPage
