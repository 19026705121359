import React from "react"
import PropTypes from "prop-types"

import QuickNav from "./Header/QuickNav"
import HeaderSimple from "./Header/HeaderSimple"
// import Header from "./Header/header";
import ColorBar from "./Header/ColorBar"
import Footer from "./Footer/Footer"
import CopyrightBar from "./Footer/CopyrightBar"
import TopButton from "./Header/TopButton"
import "./styles/layout.scss"

const Layout2 = ({ children }) => {
  return (
    <div className="app-container" id="home">
      {/* <ColorBar />
      <QuickNav /> */}
      <HeaderSimple/>
      <div>
        <main>{children}</main>
        <Footer />
        <CopyrightBar />
        <TopButton />
      </div>
      <ColorBar />
    </div>
  )
}

Layout2.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout2
