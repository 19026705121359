import React from "react"
import { Link } from "gatsby"
import AnchorLink from "react-anchor-link-smooth-scroll"
import "../styles/App.scss"

const HeaderSimple = () => {
  return (
    <div className="nav-bar">
      <h1 className="main-header">
        <Link id="header-font" to={"/"}>
          Dr. Americo Fernandes <span>Family Dentistry</span>
        </Link>
      </h1>
      <div className="nav-bar-links">
        <Link className="nav-bar-link" to="/">
          Back to Home
        </Link>
      </div>
      <AnchorLink offset={100} className="inquire-button-wrapper" href="#email">
        <button className="inquire-button">Inquire</button>
      </AnchorLink>
    </div>
  )
}

export default HeaderSimple
