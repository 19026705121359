import React from "react"

import "../styles/App.scss"

function ImplantPage() {
  return (
    <div id="implant-page-full">
      <div className="hr-wrapper">
        <hr />
      </div>
      <h1 id="implant-header">Dental Implants</h1>
      <div className="implant-page-wrapper">
        <div className="implant-info-wrapper">
          <div id="for-you">
            <p>
              If you need to replace a missing tooth or many missing teeth and
              your dentist does not offer you dental implant services, please
              contact our office. We can replace your missing teeth with
              implants very efficiently, with minimal discomfort, at a
              reasonable fee. Feel free to continue seeing your current dentist
              for all your other oral care needs. Dental implants are the best
              choice you can make for your oral health. Replacing your missing
              teeth with a Bridge will result in unnecessary and irreversible
              damage to the adjacent teeth and the premature loss of those teeth
              when compared to dental implant tooth replacement. Replacing a
              missing tooth with a bridge should always be considered a
              treatment of last resort. Dental implants provide a natural
              looking tooth replacement while being beneficial for your oral
              health compared to other treatments.
            </p>
            <h2>What is a Dental Implant?</h2>
            <p>
              A dental implant is an artificial insert made of Titanium that is
              placed in the patients jawbone where a tooth is missing in order
              to replace the natural root. After the implant is set and the bone
              is healed, an artificial replacement tooth is attached to the
              implant.
            </p>
            <h2>Are Dental Implants the right choice for you?</h2>
            <p>
              In order to be able to recieve dental implants, you need to be in
              generally good physical and oral health. Next, we need to check if
              you have enough bone on the jawbone in order to insert the
              implant. When teeth are missing, the jawbone may shrink, which may
              make you eligible for a bone graft. Through an examination
              process, we will be able to determine if you are eligible for
              dental implants.
            </p>
          </div>
          <div id="procedure">
            <h2>Will I need to be sedated?</h2>
            <p>
              All our implant treatments are performed with local anaesthesia,
              putting the patient at lower risk for complications when compared
              to Sedation and General Anaesthesia... similar to the placement of
              a typical dental filling but much faster. Most of Dr. Fernandes'
              patients receiving dental implant treatment do not require any
              kind of postoperative pain management, meaning patients can return
              to normal activities immediately after implant placement.
            </p>
          </div>
          <div id="success-rate">
            <h2>Can there be any complications with the procedure?</h2>
            <p>
              No one can guarantee a dental implant is going to successfully
              integrate into a patient's mouth, but more than 95% of dental
              implants will integrate successfully. If any of our patients do
              not experience successful dental implant integration, even up to
              five years after, we will refund all treatment costs.
            </p>
          </div>
          <p>
            If you have any additional questions about dental implant tooth
            replacement that we have not answered in the information above,
            please feel free to email us with your questions using the form
            below!
          </p>
        </div>
        <div className="cost-checklist .box-shadow">
          <h2 id="cost-header">Cost of Treatment</h2>
          <p>
            The efficiencies and improvements to dental implant treatments that
            Dr. Fernandes has implemented over the many years of practice, have
            resulted in significantly lower treatment costs to his patients. His
            fees are extremely affordable, especially when compared to the
            industry average which typically cost between $5000 and $6000.
          </p>
          <h3>Option 1</h3>
          <div className="feature main">
            Single complete tooth replacement, which includes the Implant and
            final crown
          </div>
          <div className="cost-point">
            <div className="feature sub">
              <span className="bullet">&#8226;</span>First implant
            </div>
            <div className="price"> $3200</div>
            <div className="feature sub">
              <span className="bullet">&#8226;</span>Each additional implant
            </div>
            <div className="price"> $2700</div>
          </div>
          <h3>Option 2</h3>
          <div className="feature main">
            Stabilization of existing complete dentures with:
          </div>
          <div className="cost-point">
            <div className="feature sub">
              <span className="bullet">&#8226;</span>2 implants
            </div>
            <div className="price"> $3500</div>
            <div className="feature sub">
              <span className="bullet">&#8226;</span>4 implants
            </div>
            <div className="price"> $6000</div>
          </div>
          <div className="cost-point sub">
            This option is for patients that already have dentures, which are
            unstable. If you need additional denture stability, we reccomend the
            option using 4 dental implants
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImplantPage
